<nav class="navbar navbar-expand-lg navbar-light bg-light navbar-fixed-top">
  <a class="navbar-brand" href="#cover">ST</a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a id="home" class="nav-link element" href="#cover"
          >Home <span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item">
        <a id="bio" class="nav-link element" href="#about-me-header">Bio</a>
      </li>
      <li class="nav-item">
        <a id="technology-tab" class="nav-link element" href="#technologies"
          >Technologies</a
        >
      </li>
      <li class="nav-item">
        <a id="project-tab" class="nav-link" href="#projects">Projects</a>
      </li>
      <li class="nav-item">
        <a
          id="resume"
          class="nav-link"
          target="_blank"
          href="../../assets/navbar/Resume.pdf"
          >Resume</a
        >
      </li>
    </ul>
  </div>
</nav>
