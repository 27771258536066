<div class="container-fluid parallax" id="cover">
  <div class="h-100 row align-items-center">
    <div class="col">
      <h1 class="text-center">Santiago Tettamanti</h1>
    </div>
  </div>
  <div>
    <a class="arrow arrow-down" title="Next" href="#about-me-header"></a>
  </div>
</div>
