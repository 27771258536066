<div id="about-me-header">
  <h1>About me</h1>
</div>
<div class="container" id="bio">
  <div class="row">
    <div class="col-lg">
      <p>
        Welcome to my website! This is a place where I like to showcase the things I can do.<br />
        <br />
        I enjoy taking on difficult challenges and turning them into
        experiences. Learning has always been a focal point in my life, as such,
        I'm always excited for the next challenge that will help me grow. <br />
        I consider myself a do it all person and this goes hand in hand with
        always wanting to learn the next thing. In my years of experience I've
        excelled in instruction, management and communication. <br />
        <br />
      </p>
    </div>
    <div class="col-lg d-none d-lg-block">
      <span class="image-cropper"
        ><img src="../../assets/bioPicture.png" alt=""
      /></span>
    </div>
  </div>
</div>
