<section id="projects">
  <h1 class="d-flex justify-content-center mb-5 fluid">Projects</h1>
  <div class="container mb-3">
    <div class="row">
      <div class="col-md-12 col-xl-6 d-flex justify-content-center">
        <div class="projects" id="yelpcamp"></div>
      </div>
      <div class="col-md-12 col-xl-6 d-flex justify-content-center">
        <div class="projects" id="elisa"></div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-12 col-xl-6 d-flex justify-content-center">
        <div class="projects" id="third"></div>
      </div>
      <div class="col-md-12 col-xl-6 d-flex justify-content-center">
        <div class="projects" id="fourth"></div>
      </div>
    </div> -->
    <br /><br /><br /><br /><br /><br />
  </div>
</section>
